import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import * as _ from 'lodash';
import axios from 'axios';
import { configs } from "../utils/configs";
import { stringify } from "uuid";

// TODO: shop >>> shops
const SHOPS = 'shop'
const baseURL = configs.baseURL

// Edit customer note
export async function editCustomerNote(shopId, customerId, currentNote, updatedNote) {
  const db = firebase.firestore();
  let batch = db.batch();
  let docRef = db.collection(SHOPS).doc(shopId).collection('customers').doc(customerId)

  // remove item to the items array (this need the full object to be sent)
  batch.update(docRef, { notes: firebase.firestore.FieldValue.arrayRemove(currentNote) });
  // add updated item to the items array
  batch.update(docRef, { notes: firebase.firestore.FieldValue.arrayUnion(updatedNote) });
  await batch.commit()
  return true
}

// Delete customer note
export async function deleteCustomerNote(shopId, customerId, currentNote) {
  const db = firebase.firestore();
  let batch = db.batch();
  let docRef = db.collection(SHOPS).doc(shopId).collection('customers').doc(customerId)

  // remove item to the items array (this need the full object to be sent)
  batch.update(docRef, { notes: firebase.firestore.FieldValue.arrayRemove(currentNote) });
  await batch.commit()
  return true
}


// Search customer by phone
// Cloud Firestore doesn't support native indexing or search for text fields in documents. So need to use a third-party search service like Algolia, Elastic Search
// https://firebase.google.com/docs/firestore/solutions/search
// +63XXXX.... format
export function searchCustomerByPhone(shopId, phone) {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    let collectionRef = db.collection(SHOPS).doc(shopId).collection('customers').where("phone", "==", phone)
    let querySnapshot = await collectionRef.get()
    let results = []
    querySnapshot.forEach(function (doc) {
      results.push({ ...doc.data(), id: doc.id })
    });
    resolve(results)
  });
}


export function searchCustomerByName(shopId, name) {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    let collectionRef = db.collection(SHOPS).doc(shopId).collection('customers').where("name", "==", name)
    let querySnapshot = await collectionRef.get()
    let results = []
    querySnapshot.forEach(function (doc) {
      results.push({ ...doc.data(), id: doc.id })
    });
    resolve(results)
  });
}



// Check for slug uniqueness
export const checkSlug = (slug) => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    let collectionRef = db.collection(SHOPS).where("slug", "==", slug)
    let querySnapshot = await collectionRef.get()
    let isUnique = querySnapshot.empty
    console.log(`${slug} is unique: `, isUnique)
    resolve(isUnique)
  });
}

export function fetchCovidDetails(slug) {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    let docRef = db.collection('covid').where('shopSlug', '==', slug)
    let snapshot = await docRef.orderBy("createdAt").get()
    let covidData = []
    snapshot.forEach(doc => {
      covidData.push({ id: doc.id, ...doc.data() })
    });
    resolve(covidData)
  });
}

export function searchCovidByPhone(slug, phone) {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    let docRef = db.collection('covid').where('shopSlug', '==', slug).where('phone', '==', phone)
    let snapshot = await docRef.orderBy("createdAt").get()
    let covidData = []
    snapshot.forEach(doc => {
      covidData.push({ id: doc.id, ...doc.data() })
    });
    resolve(covidData)
  });
}

export function updateUser(uid, updatedData) {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();

    try {
      await db.collection("shopAdmins").doc(uid).update(updatedData)
      resolve(true)
    } catch (error) {
      reject(false)
    }

  });
}

export function updateShop(shopId, updatedData) {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();

    try {
      await db.collection(SHOPS).doc(shopId).update(updatedData)
      resolve(true)
    } catch (error) {
      reject(false)
    }

  });
}

// Fetch appointments needed for analytics
export function fetchAppointmentsForAnalytics(shopId, startDate, endDate) {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();

    let collectionRef = db.collection(SHOPS).doc(shopId).collection('appointments')
      .where('date', '>', startDate)
      .where('date', '<', endDate)
    let querySnapshot = await collectionRef.get()
    let results = []
    querySnapshot.forEach(function (doc) {
      let appointment = doc.data()

      // Convert firestore timestamp to JS date object
      let date = appointment.date.toDate()
      appointment.date = date

      appointment.id = doc.id
      results.push(appointment)
    });
    resolve(results)
  });
}

export async function fetchGraphData(shopId) {
  // const db = firebase.firestore();
  // let docRef = db.collection(SHOPS).doc(shopId)
  // let doc = await docRef.get()
  // let shop = doc.data()
  // shop.shopId = doc.id
  // shop.id = doc.id // TODO remove this after inspection
  // return shop
}

// Shop
export async function fetchBranches(shopId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/branch/get/list?shopId=${shopId}`, configs)
  return res
}

// Fetch shop data
export async function fetchBusinessTypes() {

  const res = await axios.get(`${baseURL}/b4u-core/businessType/get/list`)
  return res
}

export async function fetchShopData(shopId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/shop/view?shopId=${shopId}`, configs)
  return res
}

//Services
export async function updateShopData(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/shop/update`, data, configs)
  return res
}

// Fetch service list
export async function fetchServiceList(branchId, serviceName, page) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/service/search?branchId=${branchId}&serviceName=${serviceName}&page=${page}&size=${12}`, configs)
  return res
}

export async function fetchServiceGroups(branchId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/serviceGroup/get?branchId=${branchId}`, configs)
  return res
}

export async function fetchTotalServiceList(branchId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/service/search?branchId=${branchId}&size=${200}`, configs)
  return res
}

// View service
export async function viewService(serviceId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/service/view?serviceId=${serviceId}`, configs)
  return res
}

// Add service
export async function addService(service) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/service/add`, service, configs)
  return res
}

// Update service
export async function editService(service) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/service/update`, service, configs)
  return res
}

// Get services by assigned employeeId
export async function getServicesByemployeeId(employeeId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/service/get/service_list_from_employee?employeeId=${employeeId}`, configs)
  return res
}

// Employees

// Fetch employee list
export async function fetchEmployeeList(branchId, employeeFirstName, employeeLastName, employeeEmail, contactNo, page) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/employee/search?employeeFirstName=${employeeFirstName}&employeeLastName=${employeeLastName}&branchId=${branchId}&email=${employeeEmail}&contactNo=${contactNo}&page=${page}&size=${9}`, configs)
  return res
}

export async function fetchTotalEmployeeList(branchId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/employee/search?branchId=${branchId}&size=${50}`, configs)
  return res
}

// Add employee
export async function addEmployee(employee) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/employee/add`, employee, configs)
  res.data.data.roleType = employee.roleType
  return res
}

// Update employee
export async function editEmployee(employee) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  console.log("Update employee object", employee)

  const res = await axios.put(`${baseURL}/b4u-core/employee/update`, employee, configs)
  res.data.data.roleType = employee.roleType
  return res
}

//Delete employee
export async function deleteEmployee(employeeId) {
  var data = {
    "employeeId": employeeId,
    "status": "Deleted"
  }
  const config = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/employee/delete`, data, config)
  return res
}

// Enable employee
export async function enableEmployee(status, employeeId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  let data = {
    "employeeId": employeeId,
    "status": status
  }

  const res = await axios.put(`${baseURL}/b4u-core/employee/enable`, data, configs)
  return res
}

// Shop Holiday

export async function addHoliday(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/shop_holiday/add`, data, configs)
  return res
}

export async function fetchUpcomingHolidays(shopId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/shop_holiday/get/list?shopId=${shopId}`, configs)
  return res
}

export async function updateHolidays(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/shop_holiday/update`, data, configs)
  return res
}


// Employee Leaves

export async function addLeave(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  try {
    const response = await axios.post(`${baseURL}/b4u-core/leave/add`, data, configs);

    return response;
  } catch (error) {
    return error.response && error.response.status === 400 ? error.response : error;
  }
}

export async function fetchUpcomingLeaves(branchId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/leave/search?branchId=${branchId}&status=Active&size=${100}`, configs)
  return res
}

export async function deleteLeaves(leave) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/leave/cancel`, leave, configs)
  return res
}

export async function submitLeavePermit(permit) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/leave/permit`, permit, configs)
  return res
}

// Facility Time off

export async function addFacilityTimeOff(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  try {
    const response = await axios.post(`${baseURL}/b4u-core/facility_time_off/add`, data, configs);

    return response;
  } catch (error) {
    return error.response && error.response.status === 400 ? error.response : error;
  }
}

export async function fetchFacilityTimeOff(branchId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/facility_time_off/search?branchId=${branchId}&size=${1000}`, configs)
  return res
}

export async function updateFacilityTimeOff(facility) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/facility_time_off/update`, facility, configs)
  return res
}


// Customers

// Load customers
export async function fetchCustomers(branchId, customerFirstName, customerLastName, customerEmail, contactNo, page) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/customer/search?branchId=${branchId}&size=${10}&page=${page}&customerFirstName=${customerFirstName}&customerLastName=${customerLastName}&email=${customerEmail}&contactNo=${contactNo}`, configs)
  return res

}


export async function addCustomer(customer) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/customer/add`, customer, configs)
  return res
}

export async function editCustomer(updateObj) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/customer/update`, updateObj, configs)
  return res
}

// Appointments

export async function addAppointment(appointment) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/appointment/add`, appointment, configs)
  return res
}

export async function confirmAppointmentApi(appointment) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/appointment/confirm`, appointment, configs)
  return res
}

// Load appointments
export async function fetchAppointments(branchId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/appointment/search?branchId=${branchId}&size=${1000}`, configs)
  return res
}

export async function editAppointment(appointment) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/appointment/update`, appointment, configs)
  return res
}

export async function cancelBooking(apointmentId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/appointment/cancel`, apointmentId, configs)
  return res
}

// Dashboard

export async function getDashboardData(endDate, startDate, branchId, shopId, nowTime, nowDate) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/dashboard/info?endDate=${endDate}&startDate=${startDate}&branchId=${branchId}&shopId=${shopId}&nowTime=${nowTime}&nowDate=${nowDate}`, configs)
  return res
}

// Analytics view

export async function getInitialAnalyticsData(todayDate, weekStartDate, weekEndDate, monthStartDate, monthEndDate, year, branchId, shopId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/dashboard/analytic/part_one?todayDate=${todayDate}&weekStartDate=${weekStartDate}&weekEndDate=${weekEndDate}&monthStartDate=${monthStartDate}&monthEndDate=${monthEndDate}&year=${year}&branchId=${branchId}&shopId=${shopId}`, configs)
  return res
}

export async function fetchGraphDataForAnalytics(startDate, endDate, branchId, shopId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/dashboard/analytic/part_two?startDate=${startDate}&endDate=${endDate}&branchId=${branchId}&shopId=${shopId}`, configs)
  return res
}


// Facilities

export async function fetchFacilityList(branchId, facilityName, page) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/facility/search?facilityName=${facilityName}&branchId=${branchId}&page=${page}&size=${12}`, configs)
  return res
}

export async function fetchFacilityListBasedOnBranch(branchId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/facility/search?branchId=${branchId}`, configs)
  return res
}

export async function fetchTotalFacilityList(branchId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/facility/search?branchId=${branchId}&size=${1000}`, configs)
  return res
}

export async function addFacility(facility) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/facility/add`, facility, configs)
  return res
}

export async function editFacility(facility) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }


  const res = await axios.put(`${baseURL}/b4u-core/facility/update`, facility, configs)
  return res
}

export async function getRemainingSMSCount(shopId) {

  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  try {
    const res = await axios.get(`${baseURL}/b4u-core/sms/get/sms_count?shopId=${shopId}`, configs)
    return res
  } catch (error) {
    console.log(error)
  }
}

export async function sendSMS(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/sms/send`, data, configs)
  return res
}

export async function sendEmail(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/email/send`, data, configs)
  return res
}

export async function fetchAppointmentsCount(branchId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/appointment/get/appointment_count?branchId=${branchId}`, configs)
  return res
}

export async function fetchCheckoutSession(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/payment/create-session`, data, configs)
  return res
}

export async function sendSuccessPayment(sessionId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/payment/success?session_id=${sessionId}`, configs)
  return res
}

export async function createPortalSession(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/payment/create-portal-session`, data, configs)
  return res
}

export async function getTrialPackageAvailability(shopId) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/trial_package/availability?shopId=${shopId}`, configs)
  return res
}

export async function fetchPaymentPackageList() {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  try {
    const res = await axios.get(`${baseURL}/b4u-core/utility/package/list`, configs)
    return res
  } catch (error) {
    console.log(error)
  }
}

export async function addBranch(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.post(`${baseURL}/b4u-core/branch/add`, data, configs)
  return res
}

export async function editBranch(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/branch/update`, data, configs)
  return res
}

export async function searchBranch(shopId, branchName, contactNo, page, size) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.get(`${baseURL}/b4u-core/branch/search?shopId=${shopId}&branchName=${branchName}&contactNo=${contactNo}&page=${page}&size=${size}`, configs)
  return res
}

export async function fetchAppointmentsReport({shopId, branchId, startDate, endDate}) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const url = `${baseURL}/b4u-core/report/appointment/list?shopId=${shopId}&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}`;

  return await axios.get(url, configs);
}

export async function updateServiceGroup(data) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.put(`${baseURL}/b4u-core/serviceGroup/update`, data, configs)
  return res
}

export async function deleteServiceGroup(id) {
  const configs = {
    headers: {
      Authorization: window.localStorage.getItem('loginToken'),
    }
  }

  const res = await axios.delete(`${baseURL}/b4u-core/serviceGroup/remove?groupId=${id}`, configs)
  return res
}