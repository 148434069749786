import * as _ from 'lodash';
import {
    fetchAppointments,
    fetchCustomers,
    addEmployee,
    addService,
    editEmployee,
    deleteEmployee,
    editCustomer,
    searchCustomerByPhone,
    searchCustomerByName,
    editService,
    updateShop,
    sendSMS,
    addAppointment,
    confirmAppointmentApi,
    editAppointment,
    addCustomer,
    addLeave,
    addHoliday,
    fetchUpcomingLeaves,
    fetchUpcomingHolidays,
    deleteLeaves,
    updateHolidays,
    cancelBooking,
    editCustomerNote,
    deleteCustomerNote,
    fetchShopData,
    fetchServiceList,
    viewService,
    fetchEmployeeList,
    updateShopData,
    updateService1,
    fetchBranches,
    enableEmployee,
    addFacilityTimeOff,
    fetchFacilityTimeOff,
    updateFacilityTimeOff,
    submitLeavePermit,
    getDashboardData,
    fetchFacilityList,
    addFacility,
    editFacility,
    fetchTotalServiceList,
    fetchTotalEmployeeList,
    fetchTotalFacilityList,
    fetchPaymentPackageList,
    addBranch,
    editBranch,
    searchBranch,

    fetchFacilityListBasedOnBranch,
    fetchServiceGroups,
    sendEmail
} from './../api/api'
import "firebase/storage";

import {
    GET_APPOINTMENTS,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENTS_ERROR,

    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_ERROR,
    GET_CUSTOMERS_FOR_PAGINATION,

    CREATE_EMPLOYEE_SUCCESS,

    CREATE_SERVICE_SUCCESS,

    UPDATE_EMPLOYEE_SUCCESS,

    DELETE_EMPLOYEE_SUCCESS,

    UPDATE_CUSTOMER_SUCCESS,

    SEARCH_CUST_BY_PHONE,
    SEARCH_CUST_BY_PHONE_SUCCESS,
    SEARCH_CUST_BY_PHONE_ERROR,

    SEARCH_CUST_BY_NAME,
    SEARCH_CUST_BY_NAME_SUCCESS,
    SEARCH_CUST_BY_NAME_ERROR,

    UPDATE_SERVICE_SUCCESS,

    UPDATE_SHOP,
    UPDATE_SHOP_SUCCESS,
    UPDATE_SHOP_ERROR,
    ADD_APPOINTMENT_SUCCESS,
    UPDATE_APPOINTMENT_SUCCESS,

    ADD_CUSTOMER_SUCCESS,

    ADD_LEAVES_SUCCESS,
    ADD_HOLIDAYS_SUCCESS,


    GET_UPCOMING_LEAVES,
    GET_UPCOMING_LEAVES_SUCCESS,
    GET_UPCOMING_LEAVES_ERROR,
    CHANGE_LEAVE_PERMISSION,

    GET_UPCOMING_HOLIDAYS,
    GET_UPCOMING_HOLIDAYS_SUCCESS,
    GET_UPCOMING_HOLIDAYS_ERROR,

    DELETE_LEAVES_SUCCESS,
    DELETE_HOLIDAYS_SUCCESS,

    CANCEL_BOOKING_SUCCESS,
    GET_SHOP_DATA_SUCCESS,
    GET_SERVICES_SUCCESS,
    GET_SERVICE_GROUPS_SUCCESS,
    GET_SERVICE_GROUPS,
    GET_SERVICE_GROUPS_ERROR,
    GET_EMPLOYEES_SUCCESS,
    GET_BRANCHES_SUCCESS,

    GET_UPCOMING_TIME_OFFS,
    GET_UPCOMING_TIME_OFF_SUCCESS,
    GET_UPCOMING_TIME_OFF_ERROR,
    ADD_TIME_OFF_SUCCESS,
    UPDATE_TIME_OFF_SUCCESS,
    GET_DASHBOARD_DATA_ERROR,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_FACILITIES_SUCCESS,
    CREATE_FACILITIES_SUCCESS,
    UPDATE_FACILITY_SUCCESS,
    GET_TOTAL_FACILITIES_SUCCESS,
    GET_TOTAL_EMPLOYEES_SUCCESS,
    GET_TOTAL_SERVICES_SUCCESS,
    GET_FACILITIES,
    GET_FACILITIES_ERROR,
    GET_SERVICES,
    GET_SERVICES_ERROR,
    GET_EMPLOYEES,
    GET_EMPLOYEES_ERROR,
    UPDATE_SUB_STATUS,
    GET_PAYMENT_PACKAGES_SUCCESS,
    CREATE_BRANCH,
    CREATE_BRANCH_SUCCESS,
    CREATE_BRANCH_ERROR,
    UPDATE_BRANCH,
    UPDATE_BRANCH_SUCCESS,
    UPDATE_BRANCH_ERROR,
    CHANGE_BRANCH_STATUS_SUCCESS,
    SEARCH_BRANCHES,
    SEARCH_BRANCHES_ERROR,
    SEARCH_BRANCHES_SUCCESS,
    SELECT_BRANCH,
    GET_DASHBOARD_DATA,

} from '../actions/types'

import { uploadToStorage } from './../utils/storage'
import { DEFAULT_PROFILE_IMAGE } from './../utils/constants'
import { configs } from '../utils/configs';

export const IMAGE_FOLDER_NAME = 'employees'
export const SHOP_IMG_FOLDER_NAME = 'shop'


export const getCustomers = (branchId, customerFirstName, customerLastName, customerEmail, contactNo, page, forSearch) => {
    return async (dispatch) => {
        if (forSearch) {
            try {
                dispatch({ type: GET_CUSTOMERS })
                let res = await fetchCustomers(branchId, customerFirstName, customerLastName, customerEmail, contactNo, page)
                let customers = res.data.data
                dispatch({ type: GET_CUSTOMERS_FOR_PAGINATION, payload: { customers } })
            } catch (error) {
                console.log(error)
                dispatch({ type: GET_CUSTOMERS_ERROR })
            }
        }
        else {
            try {
                dispatch({ type: GET_CUSTOMERS })
                let res = await fetchCustomers(branchId, customerFirstName, customerLastName, customerEmail, contactNo, page)
                let customers = res.data.data
                dispatch({ type: GET_CUSTOMERS_SUCCESS, payload: { customers } })
            } catch (error) {
                console.log(error)
                dispatch({ type: GET_CUSTOMERS_ERROR })
            }
        }
    }
}

export const updateCustomerNote = (shopId, customerId, currentNote, updateNote, updateObject, callBack) => {
    return async (dispatch) => {
        try {
            // TODO: Test error handling. Looks like errors are not handled
            let success = await editCustomerNote(shopId, customerId, currentNote, updateNote)
            if (success) {
                dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: { success, customerId, updateObject } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }
    }
}

export const removeCustomerNote = (shopId, customerId, currentNote, updateObject, callBack) => {
    return async (dispatch) => {
        try {
            // TODO: Test error handling. Looks like errors are not handled
            let success = await deleteCustomerNote(shopId, customerId, currentNote)
            if (success) {
                dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: { success, customerId, updateObject } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }
    }
}

export const searchCustByPhone = (shopId, phone) => {
    return async (dispatch) => {
        dispatch({ type: SEARCH_CUST_BY_PHONE })
        try {
            let customers = await searchCustomerByPhone(shopId, phone)
            dispatch({ type: SEARCH_CUST_BY_PHONE_SUCCESS, payload: { customers } })
        } catch (error) {
            console.log(error)
            dispatch({ type: SEARCH_CUST_BY_PHONE_ERROR })
        }
    }
}

export const searchCustByName = (shopId, name) => {
    return async (dispatch) => {
        dispatch({ type: SEARCH_CUST_BY_NAME })
        try {
            let customers = await searchCustomerByName(shopId, name)
            dispatch({ type: SEARCH_CUST_BY_NAME_SUCCESS, payload: { customers } })
        } catch (error) {
            console.log(error)
            dispatch({ type: SEARCH_CUST_BY_NAME_ERROR })
        }
    }
}

// export const updateService = (shopId, serviceId, updatedData, callBack) => {
//     return async (dispatch) => {

//         try {
//             let success = await editService(shopId, serviceId, updatedData)
//             if (success) {
//                 dispatch({ type: UPDATE_SERVICE_SUCCESS, payload: { id: serviceId, updatedData } })
//                 callBack(true)
//             } else {
//                 throw new Error()
//             }
//         } catch (error) {
//             console.log(error)
//             callBack(false)
//         }
//     }
// }

export const sendReminder = (data, callBack) => {
    return async (dispatch) => {

        try {
            let success = await sendSMS(data)
            if (success.status == 200) {
                callBack(true)
            } else {
                callBack(false)
                throw new Error()
            }
        } catch (error) {
            console.log(_.get(error, 'response.data.message'));
            callBack(false)
        }
    }
}

export const sendEmailReminder = (data, callBack) => {
    return async (dispatch) => {
        try {
            let success = await sendEmail(data)
            if (success.status == 200) {
                callBack(true)
            } else {
                callBack(false)
                throw new Error()
            }
        } catch (error) {
            console.log(_.get(error, 'response.data.message'));
            callBack(false)
        }
    }
}

// New
// Get shop data
export const getShopData = (shopId) => {
    return async (dispatch) => {
        try {
            let res = await fetchShopData(shopId)
            let shopData = res.data.data
            dispatch({ type: GET_SHOP_DATA_SUCCESS, payload: { shopData } })
        } catch (error) {
            console.log(_.get(error, 'response.data.message'));
        }

    }
}

// Get shop data
export const getBranches = (shopBranches) => {
    return async (dispatch) => {
        let branches = shopBranches.data.data.branchArr
        dispatch({ type: GET_BRANCHES_SUCCESS, payload: { branches } })
    }
}

// Update shop
export const updateShopDetails = (updatedData, callBack) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_SHOP })

        try {

            // Upload image
            if (typeof updatedData.imageUrl === 'object' && updatedData.imageUrl !== null) {

                let suffix = "_u" + (new Date()).getTime().toString()
                let { url, imageRef } = await uploadToStorage(updatedData.imageUrl, SHOP_IMG_FOLDER_NAME, updatedData.businessId, updatedData.businessId, suffix)
                console.log("IMAGE URL", url, imageRef)
                updatedData.imageUrl = url

            }

            // Upload business logo image
            if (typeof updatedData.logoUrl === 'object' && updatedData.logoUrl !== null) {
                let suffix = "_u" + (new Date()).getTime().toString()
                let { url, imageRef } = await uploadToStorage(updatedData.logoUrl, SHOP_IMG_FOLDER_NAME, updatedData.businessId, updatedData.businessId, suffix)
                console.log("LOGO IMAGE URL", url, imageRef)
                updatedData.logoUrl = url
            }

            let success = await updateShopData({ ...updatedData, siteName: configs.deployementName })
            if (success) {
                updatedData.advertisements = success.data.data.advertisements;

                dispatch({ type: UPDATE_SHOP_SUCCESS, payload: { updatedData } })
                callBack(true)
            } else {
                callBack(false)
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            dispatch({ type: UPDATE_SHOP_ERROR })
            callBack(false)
        }
    }
}

// Get service list
export const getServices = (branchId, serviceName, page) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SERVICES })
            let res = await fetchServiceList(branchId, serviceName, page)
            let services = res.data.data
            dispatch({ type: GET_SERVICES_SUCCESS, payload: { services } })
        } catch (error) {
            dispatch({ type: GET_SERVICES_ERROR })
            console.log(error)
        }
    }
}

export const getServiceGroups = (branchId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SERVICE_GROUPS })
            const res = await fetchServiceGroups(branchId)
            dispatch({ type: GET_SERVICE_GROUPS_SUCCESS, payload: { groups: res.data.data } })
        } catch (error) {
            dispatch({ type: GET_SERVICE_GROUPS_ERROR })
            console.log(error)
        }
    }
}

export const getTotalServices = (branchId) => {
    return async (dispatch) => {
        let res = await fetchTotalServiceList(branchId)
        let totalServices = res.data.data
        dispatch({ type: GET_TOTAL_SERVICES_SUCCESS, payload: { totalServices } })
    }
}

// createService
export const createService = (service, callBack) => {
    return async (dispatch) => {

        try {
            if (typeof service.serviceIconUrl === 'object' && service.serviceIconUrl !== null) {
                let suffix = "_u" + (new Date()).getTime().toString()
                let { url } = await uploadToStorage(service.serviceIconUrl, SHOP_IMG_FOLDER_NAME, service.serviceId, service.serviceId, suffix)

                service.serviceIconUrl = url
            }

            let res = await addService(service)
            let data = res.data.data
            console.log("Add service response", data)
            dispatch({ type: CREATE_SERVICE_SUCCESS, payload: { data } })
            callBack(true)
        } catch (error) {
            console.log(error)
            callBack(false, error)
        }
    }
}

// Update service
export const updateService = (serviceData, callBack) => {
    return async (dispatch) => {

        try {
            // Upload Service Icon
            if (typeof serviceData.serviceIconUrl === 'object' && serviceData.serviceIconUrl !== null) {
                let suffix = "_u" + (new Date()).getTime().toString()
                let { url } = await uploadToStorage(serviceData.serviceIconUrl, SHOP_IMG_FOLDER_NAME, serviceData.serviceId, serviceData.serviceId, suffix)

                serviceData.serviceIconUrl = url
            }

            let response = await editService(serviceData)

            // Set data to edited local state
            let data = serviceData
            // Overwrite local state's advancePriceList with backend response's advancedPriceList
            data.advancedPriceList = response.data.data.advancedPriceList
            data.additionalPricingList = response.data.data.additionalPricingList;

            if (response.status === 200) {
                dispatch({ type: UPDATE_SERVICE_SUCCESS, payload: { data, response: response.data.data } })
                console.log("edit service response", response)
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false, error)
        }
    }
}

// Get Employee list
export const getEmployees = (branchId, employeeFirstName, employeeLastName, employeeEmail, contactNo, page) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_EMPLOYEES })
            let res = await fetchEmployeeList(branchId, employeeFirstName, employeeLastName, employeeEmail, contactNo, page)
            let employees = res.data.data
            dispatch({ type: GET_EMPLOYEES_SUCCESS, payload: { employees } })
        } catch (error) {
            console.log(_.get(error, 'response.data.message'));
            dispatch({ type: GET_EMPLOYEES_ERROR })
        }
    }
}

export const getTotalEmployees = (branchId) => {
    return async (dispatch) => {
        let res = await fetchTotalEmployeeList(branchId)
        let totalEmployees = res.data.data

        console.log("employee list", res.data.data.pageList[0].employeeDTOList)
        dispatch({ type: GET_TOTAL_EMPLOYEES_SUCCESS, payload: { totalEmployees } })
    }
}

const sanitizeEmail = (email) => {
    return email
        .replace(/[^a-zA-Z0-9]/g, '')  // Replace non-alphanumeric characters with hyphens
        .toLowerCase();                // Convert to lowercase
}


// Add Employee
export const createEmployee = (employee, callBack, image, businessId) => {
    return async (dispatch) => {
        try {
            // Sanitize the email
            const sanitizedEmail = sanitizeEmail(employee.email);

            // Upload image
            if (image) {
                let { url, imageRef } = await uploadToStorage(image, IMAGE_FOLDER_NAME, businessId, sanitizedEmail)
                console.log("IMAGE URL", url, imageRef)
                employee.imageUrl = url
            } else {
                employee.imageUrl = DEFAULT_PROFILE_IMAGE
            }
            let res = await addEmployee(employee)
            let data = res.data.data
            console.log("Emlopyee Response", res)
            let enable = await enableEmployee(employee.status, data.employeeId)
            if (enable.status == 200) {
                data.status = employee.status
                data.genderPreferenceService = employee.genderPreferenceService
            }
            console.log("Employee enable response", enable)
            callBack(true)
            dispatch({ type: CREATE_EMPLOYEE_SUCCESS, payload: { data } })
            // callBack(true,employee)
        } catch (error) {
            console.log(error)
            console.log(_.get(error, 'response.data.message'));
            callBack(false, _.get(error, 'response.data.message'))
        }
    }
}

// Update Employee
export const updateEmployee = (employee, image, businessId, callBack) => {
    return async (dispatch) => {

        try {
            // Sanitize the email
            const sanitizedEmail = sanitizeEmail(employee.email);

            // Upload image
            if (typeof image === 'object' && image !== null) {
                // let promiseArray = []

                let suffix = "_u" + (new Date()).getTime().toString()
                let { url, imageRef } = await uploadToStorage(image, IMAGE_FOLDER_NAME, businessId, sanitizedEmail, suffix)
                console.log("IMAGE URL", url, imageRef)
                employee.imageUrl = url

                // delete old image
                // if (originalData.imageRef) {
                //     let storageDeletePromise = firebase.storage().ref().child(originalData.imageRef).delete()
                //     promiseArray.push(storageDeletePromise)
                // }

                // let response = await Promise.all(promiseArray)
                // let { imageRef, url } = response[0]
                // console.log('imageRef: ', imageRef)

                // updateObject.image = url
                // updateObject.imageRef = imageRef
            }

            let success = await editEmployee(employee)
            let updatedEmployee = success.data.data
            updatedEmployee.genderPreferenceService = employee.genderPreferenceService
            if (success) {
                dispatch({ type: UPDATE_EMPLOYEE_SUCCESS, payload: { updatedEmployee } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }
    }
}

//DELETE EMPLOYEE
export const removeEmployee = (employeeId, callBack) => {
    return async (dispatch) => {
        try {
            let response = await deleteEmployee(employeeId)
            if (response.status == 200) {
                dispatch({ type: DELETE_EMPLOYEE_SUCCESS, payload: { employeeId: employeeId } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }
    }
}
// shop Holidays

// Function for submit holidays
export const submitHolidays = (data, callBack) => {
    return async (dispatch) => {

        try {
            let res = await addHoliday(data)
            let holidays = res.data.data
            if (res.status == 200) {
                dispatch({ type: ADD_HOLIDAYS_SUCCESS, payload: { holidays: holidays } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }

    }
}

//Function for getUpcomingHolidays
export const getUpcomingHolidays = (shopId) => {
    return async (dispatch) => {

        dispatch({ type: GET_UPCOMING_HOLIDAYS })

        try {
            let res = await fetchUpcomingHolidays(shopId)
            let holidays = res.data.data.shopHolidayArr

            dispatch({ type: GET_UPCOMING_HOLIDAYS_SUCCESS, payload: { holidays } })
        } catch (error) {
            console.log(error)
            dispatch({ type: GET_UPCOMING_HOLIDAYS_ERROR })
        }
    }
}

//Function for removeHolidays
export const removeHolidays = (data, holidayIds, callBack) => {
    return async (dispatch) => {

        try {
            let response = await updateHolidays(data)
            let removeHoliday = response.data.data
            if (response.status == 200) {
                dispatch({ type: DELETE_HOLIDAYS_SUCCESS, payload: { holidayIds: holidayIds } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }

    }
}

// Leaves
// Fetch Leaves
export const submitLeaves = (data, callBack) => {
    return async (dispatch) => {

        try {
            let res = await addLeave(data)
            let leaves = res.data.data
            if (res.status == 200) {
                dispatch({ type: ADD_LEAVES_SUCCESS, payload: { leaves: leaves } })
                callBack(true)
            } else {
                callBack(false, res.data ? res.data.message : null)
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }

    }
}

export const getUpcomingLeaves = (branchId) => {
    return async (dispatch) => {

        dispatch({ type: GET_UPCOMING_LEAVES })

        try {
            let res = await fetchUpcomingLeaves(branchId)
            let leaves = res.data.data.pageList[0].leaveList
            dispatch({ type: GET_UPCOMING_LEAVES_SUCCESS, payload: { leaves } })
        } catch (error) {
            console.log(error)
            dispatch({ type: GET_UPCOMING_LEAVES_ERROR })
        }
    }
}

export const removeLeaves = (data, leaveIds, callBack) => {
    return async (dispatch) => {

        try {
            let response = await deleteLeaves(data)
            let removedLeave = response.data.data
            if (response.status == 200) {
                dispatch({ type: DELETE_LEAVES_SUCCESS, payload: { leaveIds: leaveIds } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }
    }
}

export const SubmitLeavePermission = (permit, callBack) => {
    return async (dispatch) => {
        try {
            let response = await submitLeavePermit(permit)
            if (response.status == 200) {
                dispatch({ type: CHANGE_LEAVE_PERMISSION, payload: { permit: permit } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }

    }
}

// Facility Time off

export const submitFacilityTimeOff = (data, callBack) => {
    return async (dispatch) => {

        try {
            let res = await addFacilityTimeOff(data)
            if (res && res.status == 200) {
                let timeOffs = res.data.data
                dispatch({ type: ADD_TIME_OFF_SUCCESS, payload: { facilityTimeOffs: timeOffs } })
                callBack(true)
            } else {
                callBack(false, res.data ? res.data.message : null)
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }

    }
}

export const getFacilityTimeOff = (branchId) => {
    return async (dispatch) => {

        dispatch({ type: GET_UPCOMING_TIME_OFFS })

        try {
            let res = await fetchFacilityTimeOff(branchId)
            let timeOffs = _.filter(res.data.data.pageList, (o) => { return o.status == "Active" })
            dispatch({ type: GET_UPCOMING_TIME_OFF_SUCCESS, payload: { timeOffs } })
        } catch (error) {
            console.log(error)
            dispatch({ type: GET_UPCOMING_TIME_OFF_ERROR })
        }
    }
}

export const updateTimeOff = (data, timeOffIds, callBack) => {
    return async (dispatch) => {

        try {
            let response = await updateFacilityTimeOff(data)
            let removedFacility = response.data.data
            if (response.status == 200) {
                dispatch({ type: UPDATE_TIME_OFF_SUCCESS, payload: { timeOffIds: timeOffIds } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }

    }
}

// Customer

export const submitCustomer = (customer, callBack) => {
    return async (dispatch) => {

        try {
            let success = await addCustomer(customer)
            let customerId = success.data.data.customerId
            if (success) {
                dispatch({ type: ADD_CUSTOMER_SUCCESS, payload: { customer: { ...customer, customerId: customerId } } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            console.log(_.get(error, 'response.data.message'));
            callBack(false, _.get(error, 'response.data.message'))
        }

    }
}

export const updateCustomer = (updateObject, callBack) => {
    return async (dispatch) => {
        try {
            // TODO: Test error handling. Looks like errors are not handled
            let response = await editCustomer(updateObject)
            if (response.status == 200) {
                let customerId = response.data.data.customerId
                dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: { customerId, updateObject } })
                callBack(true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }
    }
}

export const getAppointments = (branchId) => {
    return async (dispatch) => {
        dispatch({ type: GET_APPOINTMENTS })
        try {
            let response = await fetchAppointments(branchId)
            let appointments = response.data.data
            console.log("appointments", appointments)
            dispatch({ type: GET_APPOINTMENTS_SUCCESS, payload: { appointments } })
        } catch (error) {
            console.log(error)
            dispatch({ type: GET_APPOINTMENTS_ERROR })
        }
    }
}

export const submitAppointment = (appointment, callBack) => {
    return async (dispatch) => {

        try {
            let response = await addAppointment(appointment)
            if (response.status == 200) {
                // let addAppointmentResponse = response.data.data
                // dispatch({ type: ADD_APPOINTMENT_SUCCESS, payload: { appointment } })
                callBack(response)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error.response.data)
            let error_message = error.response.data.message
            if (error_message.includes("Appointments are fully booked")) {
                callBack('Appointments are fully booked')
            } else if (error_message.includes("The Email Address entered already exists in the system")) {
                callBack('Sorry! You can not place an appointment with this email. Please try again with another email')
            } else {
                callBack("Something went wrong. Please try again")
            }
        }

    }
}

export const confirmAppointment = (appointment, cb) => {
    return async (dispatch) => {
        try {
            let response = await confirmAppointmentApi(appointment)
            if (response.status == 200) {
                cb(response)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error.response.data)
            let error_message = error.response.data.data[0].message
            if (error_message.includes("Appointment_list_validate_error")) {
                cb("Appoinments are fully booked")
            } else {
                cb(error.response.data.message)
            }
        }

    }
}

export const updateAppointment = (appointmentId, updatedData, callBack) => {
    return async (dispatch) => {
        try {
            let response = await editAppointment(updatedData)
            const responseUpdatedData = response.data?.data;

            if (response.status == 200) {
                dispatch({ type: UPDATE_APPOINTMENT_SUCCESS, payload: { id: appointmentId, updatedData: responseUpdatedData ? responseUpdatedData: updatedData } })
                callBack(true, updatedData) //Update data passing for view appointment
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }
    }
}

export const cancelAppointment = (appointmentId, callBack) => {
    return async (dispatch) => {

        try {
            let response = await cancelBooking(appointmentId)
            if (response.status == 200) {
                dispatch({ type: CANCEL_BOOKING_SUCCESS, payload: { appointmentId } })
                callBack(true, true)
            } else {
                throw new Error()
            }
        } catch (error) {
            console.log(error)
            callBack(false)
        }

    }
}

export const fetchDashboardData = (endDate, startDate, branchId, shopId, nowTime, nowDate) => {
    return async (dispatch) => {
        dispatch({ type: GET_DASHBOARD_DATA })
        try {
            let response = await getDashboardData(endDate, startDate, branchId, shopId, nowTime, nowDate)
            if (response.status == 200) {
                let dashboardData = response.data.data
                dispatch({ type: GET_DASHBOARD_DATA_SUCCESS, payload: { dashboardData: dashboardData } })
            }
            else {
                throw new Error()
            }
        }
        catch (error) {
            console.log(error)
            dispatch({ type: GET_DASHBOARD_DATA_ERROR })
        }
    }
}

// Facility
// Get Facility list
export const getFacilities = (branchId, facilityName, page) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_FACILITIES })
            let res = await fetchFacilityList(branchId, facilityName, page)
            let facilities = res.data.data
            dispatch({ type: GET_FACILITIES_SUCCESS, payload: { facilities } })
        } catch (error) {
            console.log(error)
            dispatch({ type: GET_FACILITIES_ERROR })
        }
    }
}

export const getTotalFacilities = (branchId) => {
    return async (dispatch) => {
        let res = await fetchTotalFacilityList(branchId)
        let totalFacilities = res.data.data

        console.log("Facilities", res.data.data)
        dispatch({ type: GET_TOTAL_FACILITIES_SUCCESS, payload: { totalFacilities } })
    }
}

// Create facility
export const createFacility = (facility, callBack) => {
    return async (dispatch) => {
        try {
            let employeeId = (new Date()).getTime().toString()
            // employee.id = employeeId

            // // Upload image
            // if (image) {
            // let { url, imageRef } = await uploadToStorage(image, IMAGE_FOLDER_NAME, 'testShop', employeeId)
            // employee.imageUrl = url
            // employee.imageRef = imageRef
            // } else {
            //     employee.image = DEFAULT_PROFILE_IMAGE
            // }
            console.log("Facility object", facility)
            let res = await addFacility(facility)
            let data = res.data.data
            console.log("Facility add Response", res)
            // let enable = await enableEmployee(employee.status, data.employeeId)
            // if(enable.status == 200){
            //     data.status = employee.status
            //     data.serviceId = employee.serviceId
            // }
            // console.log("Employee enable response", enable)
            callBack(true)
            dispatch({ type: CREATE_FACILITIES_SUCCESS, payload: { data } })
            // callBack(true,employee)
        } catch (error) {
            console.log(error)
            callBack(false)
        }
    }
}

// Update facility
export const updateFacility = (facility, callBack) => {
    return async (dispatch) => {

        try {
            let res = await editFacility(facility)
            let updatedFacility = res.data.data
            dispatch({ type: UPDATE_FACILITY_SUCCESS, payload: { updatedFacility } })

            // To get update facility list for add appointment section
            let getFacilityListForAppointment = await fetchTotalFacilityList(facility.branchId)
            let totalFacilities = getFacilityListForAppointment.data.data
            dispatch({ type: GET_TOTAL_FACILITIES_SUCCESS, payload: { totalFacilities } })

            // To get update facility list for search section
            dispatch({ type: GET_FACILITIES })
            let getFacilityListForSearch = await fetchFacilityListBasedOnBranch(facility.branchId)
            let facilities = getFacilityListForSearch.data.data
            dispatch({ type: GET_FACILITIES_SUCCESS, payload: { facilities } })

            callBack(true)
        } catch (error) {
            console.log(error)
            callBack(false)
        }
    }
}

export const updateSubStatus = () => {
    return ({ type: UPDATE_SUB_STATUS })
}

export const getPaymentPackageList = () => {
    return async (dispatch) => {
        try {
            let res = await fetchPaymentPackageList()
            let paymentPackageList = res.data.data.paymentPackageInfoList
            dispatch({ type: GET_PAYMENT_PACKAGES_SUCCESS, payload: { paymentPackageList } })
        } catch (error) {
            console.log(_.get(error, 'response.data.message'));
        }
    }
}

export const createBranch = (data, callBack) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_BRANCH })
        try {
            let res = await addBranch(data)
            let newBranch = res.data.data
            dispatch({ type: CREATE_BRANCH_SUCCESS, payload: { newBranch } })
            callBack(true)

        } catch (error) {
            dispatch({ type: CREATE_BRANCH_ERROR })
            callBack(false)
            console.log(_.get(error, 'response.data.message'));
        }
    }
}

export const updateBranch = (data, callBack) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_BRANCH })
        try {
            let res = await editBranch(data)
            let updatedBranch = res.data.data
            dispatch({ type: UPDATE_BRANCH_SUCCESS, payload: { updatedBranch } })
            callBack(true)

        } catch (error) {
            dispatch({ type: UPDATE_BRANCH_ERROR })
            callBack(false, _.get(error, 'response.data.message'))
            console.log(error)
            console.log(_.get(error, 'response.data.message'));
        }
    }
}

export const searchBranches = (shopId, branchName, contactNo, page, size) => {
    return async (dispatch) => {
        dispatch({ type: SEARCH_BRANCHES })
        try {
            let res = await searchBranch(shopId, branchName, contactNo, page, size)
            let branches = res.data.data
            dispatch({ type: SEARCH_BRANCHES_SUCCESS, payload: { branches } })
        } catch (error) {
            console.log(error)
            dispatch({ type: SEARCH_BRANCHES_ERROR })
        }
    }
}

export const selectBranch = (branch) => {
    return async (dispatch) => {
        dispatch({ type: SELECT_BRANCH, payload: { branch } })
    }
}
